import React, { useState, useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import {
  MapContainer,
  Marker,
  Polygon,
  Popup,
  TileLayer,
  useMap,
} from "react-leaflet";

import L from "leaflet";

import _getDataUtils from "../../utils/_getData.utils";
import "./PokeMap.css";
import Autocomplete from "react-autocomplete";
import { Typeahead } from "react-bootstrap-typeahead";
import Markers from "../../components/Markers";

const PokeMap = (props) => {
  const [pokestops, setPokestops] = useState([]);
  const [gyms, setGyms] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const defaultPos = [
    {
      lat: 43.610769,
      lon: 3.876716,
    },
  ];
  const [zoomed, setZoomed] = useState(false);

  const [pokestopsVisible, setPokestopsVisible] = useState(false);
  const [gymsVisible, setGymsVisible] = useState(true);

  const [isLoading, setIsLoading] = useState(false);
  const [selectedOnMap, setSelectedOnMap] = useState("");

  // const map = useMap();

  const iconPokestop = new L.Icon({
    iconUrl:
      "https://raw.githubusercontent.com/nileplumb/PkmnShuffleMap/master/UICONS//pokestop/0.png",
    iconRetinaUrl:
      "https://raw.githubusercontent.com/nileplumb/PkmnShuffleMap/master/UICONS//pokestop/0.png",
    // iconAnchor: null,
    // popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(30, 30),
    // className: 'leaflet-div-icon'
  });

  const iconGym = new L.Icon({
    iconUrl:
      "https://raw.githubusercontent.com/nileplumb/PkmnShuffleMap/master/UICONS//gym/0.png",
    iconRetinaUrl:
      "https://raw.githubusercontent.com/nileplumb/PkmnShuffleMap/master/UICONS//gym/0.png",
    // iconAnchor: null,
    // popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(30, 30),
    // className: 'leaflet-div-icon'
  });

  // S2 GRID

  // const MyS2GridLayer = () => {
  //   const map = useMap();

  //   // Config des options S2 Grid
  //   const gridOptions = {
  //     minLevel: 10, // Niveau minimal de la grille S2
  //     maxLevel: 15, // Niveau maximal de la grille S2
  //     resolution: 10, // Résolution de la grille (10 signifie que chaque cellule couvre environ 1 km²)
  //     fillOpacity: 0.2,
  //     color: "blue",
  //   };
  //   // Créez une instance S2GridLayer avec les options
  //   const Mys2GridLayer = new S2GridLayer(gridOptions);

  //   // Ajoutez la couche à la carte
  //   Mys2GridLayer.addTo(map);

  //   return null;
  // };

  // const getPolyVector = (s2cellId, polyline) => {
  //   const s2cell = new S2Cell(new S2CellId(s2cellId.toString()));
  //   const poly = [];
  //   const revPoly = [];
  //   for (let i = 0; i <= 3; i += 1) {
  //     const coordinate = s2cell.getVertex(i);
  //     const point = new S2Point(coordinate.x, coordinate.y, coordinate.z);
  //     const latLng = S2LatLng.fromPoint(point);
  //     poly.push([latLng.latDegrees, latLng.lngDegrees]);
  //     revPoly.push([latLng.lngDegrees, latLng.latDegrees]);
  //   }
  //   if (polyline) {
  //     poly.push(poly[0]);
  //     revPoly.push(revPoly[0]);
  //   }

  //   return { poly, revPoly };
  // };

  // const s2cellId = 1348457587765411840;
  // const s2cellId = 1348457591633608704;

  // Remplacez par un identifiant de cellule S2 valide
  // const polyline = true; // Spécifiez si vous souhaitez fermer la boucle du polygone

  // const { poly, revPoly } = getPolyVector(s2cellId, polyline);

  useEffect(() => {
    // Charger les fichiers JSON ici
    fetchAllGyms();
    fetchAllPokestops();
    setIsLoading(true);
    // if (selectedItem) console.log("item", selectedItem);
  }, [selectedItem]);

  const fetchAllGyms = async () => {
    setGyms(
      await _getDataUtils(
        "get",
        "https://raw.githubusercontent.com/Namsnugax/pokedex-json/master/arene.json"
      )
    );
  };

  const fetchAllPokestops = async () => {
    setPokestops(
      await _getDataUtils(
        "get",
        "https://raw.githubusercontent.com/Namsnugax/pokedex-json/master/pokestop.json"
      )
    );
  };

  // console.log("gyms", gyms);
  // console.log("pokestops", pokestops);

  const handleSelection = (item) => {
    setSelectedItem(item);
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleMarkerClick = (marker) => {
    setSelectedMarker(marker);
  };

  const ComposantMap = () => {
    return (
      <>
        <MapContainer
          center={
            selectedItem
              ? [selectedItem[0]?.lat, selectedItem[0]?.lon]
              : [43.610769, 3.876716]
          }
          zoom={zoomed ? 20 : 13}
          scrollWheelZoom={true}
        >
          {/*  */}
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright%22%3EOpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />

          {/* <Markers gyms={gyms} gymsVisible={gymsVisible} /> */}

          {/* On boucle sur la liste des pokestop pour les afficher comme marker sur la map */}
          {gymsVisible &&
            gyms.map((gym) => {
              return (
                <Marker
                  position={[gym.lat, gym.lon]}
                  icon={iconGym}
                  key={iconGym + gym.lat + gym.lon}
                >
                  <Popup>
                    <div>{gym.name}</div>
                    <div>
                      <img
                        src={gym.url}
                        alt={gym.name}
                        className="col-md-12"
                      ></img>
                    </div>
                  </Popup>
                </Marker>
              );
            })}

          {/* On boucle sur la liste des pokestop pour les afficher comme marker sur la map */}
          {pokestopsVisible &&
            pokestops.map((pokestop) => {
              return (
                <Marker
                  position={[pokestop.lat, pokestop.lon]}
                  icon={iconPokestop}
                >
                  <Popup>
                    <div>{pokestop.name}</div>
                    <div>
                      <img
                        src={pokestop.url}
                        alt={pokestop.name}
                        className="img-popup-gyms"
                      ></img>
                    </div>
                  </Popup>
                </Marker>
              );
            })}

          {/* <Polygon positions={poly} color="blue" fillOpacity={0.2} /> */}
        </MapContainer>
      </>
    );
  };

  return (
    <div>
      <Container>
        <div className="row mt-3 mb-3">
          {/* Input de sélection du pokestop  */}
          <Typeahead
            z-index={4}
            className="col-3"
            id="basic-example"
            maxResults={10}
            emptyLabel="Aucun pokestop"
            selected={""}
            onChange={(e) => {
              setSelectedItem(e.length === 0 ? defaultPos : e);
              setZoomed(e.length === 0 ? false : true);
            }}
            placeholder="Rechercher un Pokéstop"
            options={pokestops}
            labelKey="name"
          />

          {/* Input de sélection d'une arene */}
          <Typeahead
            z-index={4}
            className="col-3"
            id="basic-example"
            maxResults={10}
            emptyLabel="Pas d'arène trouvé"
            selected={""}
            onChange={(e) => {
              setSelectedItem(e.length === 0 ? defaultPos : e);
              setZoomed(e.length === 0 ? false : true);
            }}
            placeholder="Rechercher une arène"
            options={gyms}
            labelKey="name"
          />

          {/* Boutons pour afficher / masquer les arenes et pokestops */}
          <div className="col-3 ">
            <Button
              className="me-2"
              variant={pokestopsVisible ? "success" : "danger"}
              id=""
              onClick={() => setPokestopsVisible(!pokestopsVisible)}
            >
              <img
                className="img-btn"
                src="https://raw.githubusercontent.com/nileplumb/PkmnShuffleMap/master/UICONS//pokestop/0.png"
                alt="Pokestop logo"
              />
            </Button>
            <Button
              className=""
              variant={gymsVisible ? "success" : "danger"}
              id=""
              onClick={() => setGymsVisible(!gymsVisible)}
            >
              <img
                className="img-btn"
                src="https://raw.githubusercontent.com/nileplumb/PkmnShuffleMap/master/UICONS//gym/0.png"
                alt="Gym logo"
              />
            </Button>
          </div>
        </div>

        {/* Map */}
        <div className="row">
          <Col md={9}>
            <ComposantMap />
          </Col>
        </div>
      </Container>
    </div>
  );
};

export default PokeMap;
