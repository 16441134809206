import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Button,
  Modal,
  InputGroup,
  FormControl,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import {
  Search,
  X,
  XLg,
  Sliders,
  EyeFill,
  EyeSlash,
} from "react-bootstrap-icons";
import { Typeahead } from "react-bootstrap-typeahead";
import _getDataUtils from "../../utils/_getData.utils";
import "./Pokedex.css";

import snorlax from "../../assets/img/snorlax.gif";
import BtnScrollUp from "./BtnScrollUp";
// import CardPokemon from "../../components/CardPokemon/CardPokemon";

const Pokedex = () => {
  const [region, setRegion] = useState("KANTO");
  const regionsList = [
    "TOUS",
    "KANTO",
    "JOHTO",
    "HOENN",
    "SINNOH",
    "UNYS",
    "KALOS",
    "ALOLA",
    "GALAR",
    "HISUI",
    "PALDEA",
  ];
  const [shinyState, setShinyState] = useState(false);
  const [costumeState, setCostumeState] = useState("withCostume");
  const [obscurState, setObscurState] = useState(false);
  const [legState, setLegState] = useState(false);
  const [ubState, setUbState] = useState(false);
  const [megaState, setMegaState] = useState(false);
  let costumeObj = {
    withCostume: "success",
    noCostume: "danger",
    onlyCostume: "info",
  };

  const [allPokemon, setAllPokemon] = useState([]);
  const [allPokemonName, setAllPokemonName] = useState([]);

  // Sur quoi map l'affichage pour afficher les poké :
  const [pokemonFiltered, setPokemonFiltered] = useState([]);
  const [search, setSearch] = useState("");
  const [errSearch, setErrSearch] = useState(false);

  // Tableau qui contient les pokémons cliqués
  const [selectedPokemon, setSelectedPokemon] = useState([]);
  const [listSelectedPokemon, setListSelectedPokemon] = useState("");
  // Variable pour le status select all/aucun
  const [selectedAllPokemon, setSelectedAllPokemon] = useState(false);
  // Variable pour le status de voir uniquement la liste des cochés
  const [seeOnlyList, setSeeOnlyList] = useState(false);

  // Loading
  const [loading, setLoading] = useState(true);

  //Bouton copier
  const [isDarkened, setIsDarkened] = useState(false);

  // Modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Tableau des pokémon dont l'image contient une exception dans l'url
  const [exceptionPokemonImage, setExceptionPokemonImage] = useState([
    "Mega Dardargnan",
    "Mega Alakazam",
  ]);

  // Etat des boutons filtres
  const [showDivFilter, setShowDivFilter] = useState(true);

  useEffect(() => {
    const fetchAllPokemon = async () => {
      setAllPokemon(
        await _getDataUtils(
          "get",
          "https://raw.githubusercontent.com/Namsnugax/pokedex-json/master/pokedex.json"
        )
      );
    };
    const fetchAllPokemonName = async () => {
      setAllPokemonName(
        await _getDataUtils(
          "get",
          "https://raw.githubusercontent.com/Namsnugax/pokedex-json/master/namePokeAndCode.json"
        )
      );
    };
    fetchAllPokemon();
    fetchAllPokemonName();
    const urlParams = new URLSearchParams(window.location.search);
    // Récupérer la liste envoyée dans l'url
    // if (urlParams.get("data")) {
    //   setSelectedPokemon(urlParams.get("data").slice(1, -1).split(","));
    // }
    if (urlParams.has("data")) {
      let dataValue = urlParams.get("data"); // On obtient la valeur "data" de l'URL
      dataValue = dataValue.slice(1, -1); // On enlève la double guillemet
      const dataList = dataValue.split(","); // On sépare les éléments en utilisant la virgule comme délimiteur
      setSelectedPokemon(dataList);
    }
  }, []);

  useEffect(() => {
    // reset du tableau contenant les pokémons filtrés si on est pas dans une recherche:
    if (search.length === 0) {
      setPokemonFiltered([]);
    }
    dynamicFilter(
      region,
      shinyState,
      costumeState,
      obscurState,
      legState,
      ubState,
      megaState
    );
    setListSelectedPokemon(selectedPokemon.map((pokemon) => pokemon).join(","));
  }, [
    allPokemon,
    costumeState,
    obscurState,
    legState,
    ubState,
    megaState,
    region,
    shinyState,
    listSelectedPokemon,
    selectedPokemon,
  ]);

  // UseEffect pour l'affichage des cartes cochées/décochées
  useEffect(() => {
    // await setPokemonFiltered(selectedPokemon);
    // On reset la liste et on relance l'affichage
    setPokemonFiltered([]);
    dynamicFilter(
      region,
      shinyState,
      costumeState,
      obscurState,
      legState,
      ubState,
      megaState
    );
    // console.log("see", pokemonFiltered, "selected", selectedPokemon);
  }, [seeOnlyList]);

  // UseEffect qui va observer l'état de la recherche :
  useEffect(() => {
    // Si fin de recherche, on appelle la fonction qui reset la recherche
    if (search.length == 0 && region == "") {
      handleDeleteSearchPokemon();
    }
    if (search.length > 0) {
      setErrSearch(false);
    }
  }, [search]);

  // UseEffect qui va observer si tous les poké sont cochés pour changer le bouton Tous/Aucun
  useEffect(() => {
    const uniqueCodesInFiltered = pokemonFiltered.map(
      (pokemon) => pokemon.uniqueCode
    );

    // Si les pokémons filtrés sont tous cochés, on laisse la case pour pouvoir tout déselectionner (afficher "AUCUN")
    if (
      uniqueCodesInFiltered.every((code) => selectedPokemon.includes(code)) &&
      uniqueCodesInFiltered.length != 0
    ) {
      setSelectedAllPokemon(true);
    } else {
      setSelectedAllPokemon(false);
    }
  }, [
    selectedPokemon,
    region,
    shinyState,
    costumeState,
    obscurState,
    legState,
    ubState,
    megaState,
  ]);

  // RECHERCHE D'UN POKE (commenté le temps de régler le soucis de lag)
  const handleSubmit = (event) => {
    event.preventDefault();
    // Do something with the search term, such as filter a list of items
    console.log(`Search term: ${search}`);
    handleSearchPokemon();
  };

  // Fonction qui vide la recherche et reset les filtres
  const handleDeleteSearchPokemon = async () => {
    // On vide la recherche si elle est pas déjà vide
    if (search.length > 0) await setSearch("");
    // On réattribut la région Kanto par défaut
    setRegion("KANTO");
    setPokemonFiltered([]);
    dynamicFilter(
      region,
      shinyState,
      costumeState,
      obscurState,
      legState,
      ubState,
      megaState
    );
    setErrSearch(false);
  };

  // Fonction de la recherche de pokémon
  const handleSearchPokemon = async (e) => {
    if (search.length > 0) {
      // On reset les pokémons filtrés pour que la recherche ait la priorité sur l'affichage
      setPokemonFiltered([]);
      // On regarde si la recherche est une suite de chiffres
      const isSearchNumber = !isNaN(search);
      // On déselectionne la région
      setRegion("");
      // Recherche dans la liste des pokémon
      allPokemon.forEach((pokemon) => {
        pokemon.a_formDatas.forEach(async (formData) => {
          // Si la recherche est un nombre ou une string
          let conditionSearch = isSearchNumber
            ? formData.uniqueCode.toLocaleLowerCase() === search
            : formData.nameFormFr.toLocaleLowerCase().includes(search);
          if (conditionSearch) {
            // Si le pokémon est dispo dans pogo
            if (formData.avalaibleInPogo) {
              await setPokemonFiltered((prevFiltered) => [
                ...prevFiltered,
                formData,
              ]);
              // Chercher dans les costumes
              getCostumeData(formData.costumeData, formData.nameFormFr);
            }
          }
        });
      });
    } else {
      // Affichage du message d'erreur
      // setErrSearch(true);
    }
  };

  const handleCostumeChange = async (e) => {
    switch (costumeState) {
      case "noCostume":
        await setCostumeState("withCostume");
        break;
      case "withCostume":
        await setCostumeState("onlyCostume");
        break;
      case "onlyCostume":
        await setCostumeState("noCostume");
        break;
      default:
        break;
    }
  };

  // const checkAllCardsSelected = () => {
  //   const cards = document.querySelectorAll(".card");
  //   console.log(cards);
  //   for (let i = 0; i < cards.length; i++) {
  //     if (!cards[i].classList.contains("darken")) {
  //       return false;
  //     }
  //   }
  //   return true;
  // };

  const handleSelectAllCards = (e) => {
    // Cacher le bouton "SELECTIONNER AUCUN" et afficher le bouton "TOUT SELECTIONNER"

    const filteredPokemonCodes = pokemonFiltered.map(
      (pokemon) => pokemon.uniqueCode
    );

    // Filtre des codes déjà dans la liste
    const uniqueNewCodes = filteredPokemonCodes.filter(
      (code) => !selectedPokemon.includes(code)
    );

    setSelectedPokemon((prevSelected) => [...prevSelected, ...uniqueNewCodes]);
    // Donner l'info que tous les pokémons sont sélectionnés
    setSelectedAllPokemon(true);
  };

  // Fonction qui désectionne toutes les cartes
  const handleSelectNoCard = async (e) => {
    // On récupère tous les codes uniques des pokémons affichés :
    const filteredPokemonCodes = pokemonFiltered.map(
      (pokemon) => pokemon.uniqueCode
    );
    // On les retire de la liste
    setSelectedPokemon((prevSelected) =>
      prevSelected.filter((code) => !filteredPokemonCodes.includes(code))
    );
    // Cacher le bouton "SELECTIONNER AUCUN" et afficher le bouton "TOUT SELECTIONNER"
    setSelectedAllPokemon(false);
  };

  // Fonction appelée lorsque l'utilisateur clique sur le bouton "Copier"
  const handleConfirmClick = () => {
    // Copie dans le presse-papiers
    navigator.clipboard.writeText(listSelectedPokemon);
    // Changer l'état pour rendre le bouton plus sombre
    setIsDarkened(true);
    // Optionnel : Revenir à l'état initial après un certain temps
    setTimeout(() => {
      setIsDarkened(false);
    }, 3000);
  };

  // Fonction qui vide la liste et décoches les cartes
  const handleDeleteList = () => {
    setListSelectedPokemon([]);
    setSelectedPokemon([]);
    // console.log("effacé");
  };

  // Fonction pour voir uniquement les cartes cliquées
  const handleSeeList = async () => {
    setSeeOnlyList(!seeOnlyList);
    await setPokemonFiltered(selectedPokemon);
    // On reset la liste et on relance l'affichage
    await setPokemonFiltered([]);
    await dynamicFilter(
      region,
      shinyState,
      costumeState,
      obscurState,
      legState,
      ubState,
      megaState
    );
  };

  // Fonction qui sera appelée lorsqu'une carte est cliquée
  const handleClick = async (e) => {
    const card = e.currentTarget;
    const code = card.getAttribute("code");
    if (selectedPokemon.includes(code)) {
      await setSelectedPokemon((prevSelected) =>
        prevSelected.filter((selectedCode) => selectedCode !== code)
      );
    } else {
      await setSelectedPokemon((prevSelected) => [...prevSelected, code]);
    }
  };

  // OLD CLICK
  // const checkedIsSelectedCards = () => {
  //   let isSelected = true;
  //   const cards = document.querySelectorAll(".card");
  //   cards.forEach((card) => {
  //     if (!card.classList.contains("")) {
  //       isSelected = false;
  //     }
  //   });
  //   if (!isSelected) {
  //     document.getElementById("btnNoCard").classList.add("d-none");
  //     document.getElementById("btnAllCard").classList.remove("d-none");
  //   } else {
  //     document.getElementById("btnAllCard").classList.add("d-none");
  //     document.getElementById("btnNoCard").classList.remove("d-none");
  //   }
  // };

  // Au clic d'un bouton région
  const handleRegionClick = async (region) => {
    // On reset les data filtrées
    await setPokemonFiltered([]);
    // On set region sur la région cliquée
    setRegion(region);
    dynamicFilter(
      region,
      shinyState,
      costumeState,
      obscurState,
      legState,
      ubState,
      megaState
    );
  };

  const dynamicFilter = async (
    region,
    shiny,
    costume,
    obscur,
    leg,
    ub,
    mega
  ) => {
    // console.log("tt", region);
    // if (pokemonSearched.length > 0 || region === "") {
    //   await setPokemonFiltered(pokemonSearched);
    // } else {

    // allPokemon.forEach((pokemon) => {
    //   pokemon.a_formDatas.forEach(async (formData) => {
    //     const isRegionMatch =
    //       region === formData.region && formData.avalaibleInPogo;
    //     const isMegaOrPrimo =
    //       mega &&
    //       (formData.genericName.startsWith("Mega") ||
    //         formData.genericName.startsWith("Primo"));
    //     const isShinyMatch =
    //       shiny === true ? shiny && formData.avalaibleShiny : shiny === false;
    //     const isLegendaryMatch = leg ? pokemon.legendary : true;
    //     const isUltraBeastMatch = ub ? pokemon.ultraBeast : true;
    //     const isObscurMatch = obscur ? obscur && formData.shadow : true;

    //     if (
    //       isRegionMatch &&
    //       (isMegaOrPrimo || !mega) &&
    //       isShinyMatch &&
    //       isLegendaryMatch &&
    //       isUltraBeastMatch &&
    //       isObscurMatch
    //     ) {
    //       if (!obscur || (obscur && formData.shadow)) {
    //         if (
    //           !(
    //             costume === "onlyCostume" &&
    //             formData.avalaibleShiny === false &&
    //             shiny
    //           )
    //         ) {
    //           // if (costume !== "noCostume" && obscur !== true) {
    //           //   if (costume === "withCostume" || costume === "onlyCostume") {
    //           //     if (
    //           //       !formData.shadow &&
    //           //       (formData.shinyShadow === false ||
    //           //         (formData.shinyShadow === true && shiny))
    //           //     ) {
    //           //       getCostumeData(formData.costumeData, formData.nameFormFr);
    //           //       console.log(formData.costumeData, formData.nameFormFr)
    //           //     }
    //           //   }
    //           // }
    //           if (
    //             !(formData.shadow === false && obscur === true) &&
    //             isShinyMatch
    //           ) {
    //             if (formData.shinyShadow === false && obscur && shiny) {
    //             } else {
    //               await setPokemonFiltered((prevFiltered) => [
    //                 ...prevFiltered,
    //                 formData,
    //               ]);
    //               setLoading(false);
    //             }
    //           }
    //         }
    //       } else {
    //         if (
    //           !formData.shadow &&
    //           (formData.shinyShadow === false ||
    //             (formData.shinyShadow === true && shiny))
    //         ) {
    //           if (costume === "onlyCostume") {
    //             getCostumeData(formData.costumeData, formData.nameFormFr);
    //           }
    //         }
    //       }
    //     }
    //   });
    // });
    allPokemon.forEach((pokemon) => {
      pokemon.a_formDatas.forEach(async (formData) => {
        // On filtre le bouton VOIR pour avoir uniquement ceux cliqué

        const selectedPokemonArray = listSelectedPokemon
          .split(",")
          .map((code) => code.trim());

        // Boucle pour filter que les cochées

        // Vérifiez manuellement la présence du code unique dans selectedPokemonArray
        let isUniqueCodePresent = false;
        for (let i = 0; i < selectedPokemonArray.length; i++) {
          if (selectedPokemonArray[i] === formData.uniqueCode) {
            isUniqueCodePresent = true;
            break;
          }
        }

        if (
          seeOnlyList ||
          // (seeOnlyList && selectedPokemonArray.includes(formData.uniqueCode)) ||
          seeOnlyList === false
        ) {
          let isRegionMatch =
            region === formData.region && formData.avalaibleInPogo;
          if (region === "TOUS" && formData.avalaibleInPogo) {
            isRegionMatch = true;
          }
          const isMegaOrPrimo =
            mega &&
            (formData.genericName.startsWith("Mega") ||
              formData.genericName.startsWith("Primo"));
          const isShinyMatch = shiny
            ? formData.avalaibleShiny &&
              ((formData.shinyShadow && shiny) || formData.shinyShadow)
            : true;

          const isLegendaryMatch = leg ? pokemon.legendary : true;
          const isUltraBeastMatch = ub ? pokemon.ultraBeast : true;
          const isObscurMatch = obscur ? formData.shadow : true;

          if (
            isRegionMatch &&
            (isMegaOrPrimo || !mega) &&
            isLegendaryMatch &&
            isUltraBeastMatch
          ) {
            // if (obscur && formData.shadow) {
            //   // Si obscur + shiny
            //   if (formData.shinyShadow === false && shiny) {
            //   } else {
            //     await setPokemonFiltered((prevFiltered) => [
            //       ...prevFiltered,
            //       formData,
            //     ]);
            //     setLoading(false);
            //   }
            // }

            // if (!obscur || (obscur && formData.shadow)) {
            if (!obscur || (obscur && formData.shadow)) {
              // Filtrage des costumes

              // Vérification des Pokémon non-obscurs
              //Si bouton obscur VERT (on veut les non obscur + les obscurs)
              if (!obscur) {
                // Si le bouton sh est coché et que le poké est dispo en sh, ou que le bouton sh n'est pas coché
                if (
                  (shiny === true && formData.avalaibleShiny) ||
                  shiny === false
                ) {
                  // Ajout des Pokémon si l'on ne filtre pas uniquement par costume ou si l'on veut les costumes
                  if (formData.costumeData) {
                    if (costume !== "noCostume") {
                      if (
                        costume === "withCostume" ||
                        costume === "onlyCostume"
                      ) {
                        // console.log(
                        //   "bug",
                        //   formData.nameFormFr,
                        //   formData.avalaibleShiny
                        // );

                        getCostumeData(
                          formData.costumeData,
                          formData.nameFormFr
                        );
                        await setPokemonFiltered((prevFiltered) => [
                          ...prevFiltered,
                          formData,
                        ]);
                        setLoading(false);
                      }
                      if (
                        costume !== "onlyCostume" ||
                        (costume === "onlyCostume" &&
                          formData.costumeData.length > 0)
                      ) {
                        // if (isShinyMatch) {
                        //   console.log("bug",formData.nameFormFr,formData.avalaibleShiny)
                        //   await setPokemonFiltered((prevFiltered) => [
                        //     ...prevFiltered,
                        //     formData,
                        //   ]);
                        //   setLoading(false);
                        // }
                      }
                    } else {
                      await setPokemonFiltered((prevFiltered) => [
                        ...prevFiltered,
                        formData,
                      ]);
                      setLoading(false);
                    }
                  } else if (costume !== "onlyCostume") {
                    await setPokemonFiltered((prevFiltered) => [
                      ...prevFiltered,
                      formData,
                    ]);
                    setLoading(false);
                  }
                }
              } else {
                // On ne veut que les obscurs
                if (formData.shadow && isShinyMatch) {
                  // console.log(isShinyMatch);
                  await setPokemonFiltered((prevFiltered) => [
                    ...prevFiltered,
                    formData,
                  ]);
                  setLoading(false);
                }
              }
            }
          }
        }
      });
    });

    // }
  };

  // Fonction pour récupérer les données costumes et les renvoyer au bon format
  const getCostumeData = async (costumeData, nameFormPoke) => {
    if (costumeData?.a_costumeName.length > 0) {
      // On regarde si c'est dans la liste des cochés (si c'est coché) :
      const selectedPokemonArray = listSelectedPokemon
        .split(",")
        .map((code) => code.trim());

      let isUniqueCodeCostumePresent = false;
      if (seeOnlyList) {
        for (const i in costumeData.a_costumeName) {
          if (selectedPokemonArray[i] === costumeData.uniqueCode) {
            isUniqueCodeCostumePresent = true;
            // console.log("cost", costumeData.uniqueCode);
            break;
          }
        }
      }

      // On récupère les infos
      for (const i in costumeData.a_costumeName) {
        let costumeInfo = [];
        if (!costumeData.a_imgCostumeName[i].includes("g2")) {
          const nameFormFr = nameFormPoke + " " + costumeData.a_costumeName[i];
          const avalaibleShiny = costumeData.a_costumeShiny[i];
          const a_imgFormName = [costumeData.a_imgCostumeName[i]];
          const uniqueCode = costumeData.a_uniqueCode[i];
          const a_imgFormShinyName = [costumeData.a_imgCostumeShinyName[i]];
          const isACostume = true;
          costumeInfo = {
            nameFormFr,
            a_imgFormName,
            uniqueCode,
            avalaibleShiny,
            // Rajout de l'image shiny pour qu'elle soit utilisé lors de la création de la carte si shiny
            a_imgFormShinyName,
            isACostume,
          };
          // if (!avalaibleShiny && shinyState && (seeOnlyList && !isUniqueCodeCostumePresent)) {
          if (!avalaibleShiny && shinyState) {
            continue;
          } else {
            setPokemonFiltered((prevFiltered) => [
              ...prevFiltered,
              costumeInfo,
            ]);
          }
        }
      }
    }
  };

  const toggleDivFilter = () => {
    setShowDivFilter(!showDivFilter);
  };

  return (
    <>
      <div className="ms-lg-5 me-lg-5 me-sm-0 ms-sm-0">
        {/* Boutons des régions */}
        <div className="d-none d-md-block">
          <Row>
            <Col>
              <div className="btn-filtre-region  ">
                {regionsList.map((regionName) => (
                  <Button
                    key={regionName}
                    variant={region === regionName ? "info" : "secondary"}
                    onClick={() => handleRegionClick(regionName)}
                    className="me-2 mt-2"
                  >
                    {regionName}
                  </Button>
                ))}
              </div>
            </Col>
          </Row>
        </div>
        {/* Liste déroulante des régions pour smartphone */}
        <div className="d-block d-md-none ms-1 mt-2">
          <Row>
            <Col>
              <DropdownButton
                id="dropdown-basic-button"
                title={region || "Selectionne une région"}
                className=""
                variant="info"
              >
                {regionsList.map((regionName) => (
                  <Dropdown.Item
                    key={regionName}
                    active={region === regionName}
                    onClick={() => handleRegionClick(regionName)}
                    variant="secondary"
                    className=""
                  >
                    {regionName}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </Col>
          </Row>
        </div>

        {/* Bloc des boutons filtres SMARTPHONE */}
        <div>
          <Button
            className="ms-1 mt-1 d-block d-md-none"
            variant={"secondary"}
            id=""
            onClick={toggleDivFilter}
          >
            <Sliders />
            &nbsp;&nbsp;
            {showDivFilter ? <EyeFill /> : <EyeSlash />}
          </Button>
        </div>
        {/* Affichage du bloc des boutons filtres, si bouton cliqué ou grand écran */}
        {showDivFilter ? (
          <>
            {/* Bloc des boutons TOUS/AUCUN + Chromatiques + Costumes + TextArea + Btn Copier/Effacer*/}
            <div className="">
              <Row>
                <Col>
                  {/* TOUS / AUCUN */}
                  {selectedAllPokemon ? (
                    <Button
                      variant="danger"
                      id="btnNoCard"
                      onClick={handleSelectNoCard}
                      className="btn-filtre-autre mt-2 "
                    >
                      SELECTIONNER AUCUN
                    </Button>
                  ) : (
                    <Button
                      className="btn-filtre-autre mt-2 "
                      variant="success"
                      id="btnAllCard"
                      onClick={handleSelectAllCards}
                    >
                      TOUT SELECTIONNER
                    </Button>
                  )}
                  <Button
                    className="btn-filtre-autre mt-2"
                    variant={shinyState ? "success" : "danger"}
                    id="btnShiny"
                    onClick={() => setShinyState(!shinyState)}
                  >
                    CHROMATIQUES
                  </Button>
                  <Button
                    className="btn-filtre-autre mt-2"
                    variant={obscurState ? "info" : "success"}
                    id="btnObscur"
                    onClick={async () => {
                      await setObscurState(!obscurState);
                      // await setCostumeState(obscurState ? "withcostume" : "noCostume");
                      // handleCostumeChange("clickObscur");
                    }}
                  >
                    OBSCURS/PURIFIÉS
                  </Button>

                  {/* Masque le bouton costume si les obscurs sont sélectionnés */}
                  {obscurState ? (
                    ""
                  ) : (
                    <Button
                      className="btn-filtre-autre mt-2"
                      variant={costumeObj[costumeState]}
                      id="btnCostume"
                      onClick={async () => {
                        await setLegState(false);
                        await setUbState(false);
                        await setMegaState(false);

                        handleCostumeChange();
                      }}
                    >
                      COSTUMES
                    </Button>
                  )}
                  <Button
                    className="btn-filtre-autre mt-2"
                    variant={legState ? "info" : "success"}
                    id="btnLeg"
                    onClick={async () => {
                      setLegState(!legState);
                      await setUbState(false);
                      // await setMegaState(false);
                      await setCostumeState("noCostume");
                    }}
                  >
                    LEGENDAIRES
                  </Button>
                  <Button
                    className="btn-filtre-autre mt-2"
                    variant={ubState ? "info" : "success"}
                    id="btnUb"
                    onClick={async () => {
                      setUbState(!ubState);
                      await setLegState(false);
                      await setMegaState(false);
                      await setCostumeState("noCostume");
                    }}
                  >
                    ULTRA CHIMERES
                  </Button>
                  <Button
                    className="btn-filtre-autre mt-2"
                    variant={megaState ? "info" : "success"}
                    id="btnMega"
                    onClick={async () => {
                      setMegaState(!megaState);
                      // await setLegState(false);
                      await setUbState(false);
                      await setCostumeState("noCostume");
                    }}
                  >
                    MEGA EVOLUTIONS
                  </Button>
                </Col>
              </Row>
            </div>
          </>
        ) : (
          ""
        )}
        <Row>
          <Col xs={12} md={4}>
            {/* Input de recherche  */}
            <Form
              onSubmit={handleSubmit}
              className="btn-filtre-autre mt-3 d-flex mb-2"
            >
              <Form.Control
                type="text"
                placeholder="Recherche"
                value={search}
                onChange={async (e) => {
                  await setSearch(e.target.value);
                }}
              />
              <Button
                className="btn-filtre-autre"
                variant={"secondary"}
                id="btnSearch"
                onClick={handleSearchPokemon}
              >
                <Search />
              </Button>
              {/* Si une recherche est en cours, afficher le bouton pour la supprimer */}
              {search ? (
                <Button
                  className="btn-filtre-autre"
                  variant={"danger"}
                  id="btnSearch"
                  onClick={handleDeleteSearchPokemon}
                >
                  <XLg />
                </Button>
              ) : (
                ""
              )}
              {/* Compteur test */}
              <div>
                &nbsp;
                {selectedPokemon.length + "/" + pokemonFiltered.length}
              </div>
            </Form>
          </Col>
          {errSearch ? (
            <div className="fst-italic">
              {" "}
              La recherche ne fonctionne qu'à partir de 3 caractères
            </div>
          ) : (
            ""
          )}
          {listSelectedPokemon.length >= 3950 ? (
            <div className="warning-text">
              <span>
                Attention la liste dépasse la limite de caractères de Discord
              </span>
            </div>
          ) : (
            ""
          )}
        </Row>
        <div>
          <Row className="">
            {/* TexteArea de la liste */}
            <Col md={10} sm={12}>
              <Form.Control
                as="textarea"
                rows={3}
                className=""
                value={listSelectedPokemon}
                disabled
              />
            </Col>
            {/* Boutons Copier / Effacer */}
            <Col md={2}>
              <Row className="justify-content-center">
                <Col sm={8} xs={3}>
                  <div className="text-center">
                    <Button
                      id="confirm-button"
                      variant={isDarkened ? "info" : "success"}
                      onClick={handleConfirmClick}
                      className="mt-1"
                    >
                      Copier
                    </Button>
                  </div>
                </Col>
                <Col md={8} xs={3}>
                  <div className="text-center">
                    <Button
                      id="delete-button"
                      variant="danger"
                      onClick={handleDeleteList}
                      className="mt-1"
                    >
                      Effacer
                    </Button>
                  </div>
                </Col>
                {/* <Col md={8} xs={3}>
                  <div className="text-center">
                    <Button
                      id="delete-button"
                      variant={seeOnlyList ? "success" : "danger"}
                      onClick={handleSeeList}
                      className="mt-1"
                    >
                      Voir
                    </Button>
                  </div>
                </Col> */}
              </Row>
            </Col>
          </Row>
        </div>

        {/* Liste des cards contenant les pokémons */}
        <div>
          <>
            <>
              <div className="card-deck m-2">
                <div className="row">
                  {pokemonFiltered.length === 0 && loading ? (
                    <div className="content-loading">
                      <div className="loading"></div>
                    </div>
                  ) : !loading && pokemonFiltered.length === 0 ? (
                    <div className="text-center mt-5 fw-bold">
                      Aucun Pokemon trouvé
                      <div className="justify-content-center mt-3">
                        <img
                          className="snorlax-img"
                          src={snorlax}
                          alt="snorlax"
                        />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                      </div>
                    </div>
                  ) : (
                    pokemonFiltered.map((pokemon) => (
                      <div
                        className="col-sm-2 col-md-2 col-xl-1 p-1 col-3"
                        key={pokemon.uniqueCode}
                      >
                        <div
                          className={`card 
                          ${
                            selectedPokemon.includes(pokemon.uniqueCode)
                              ? "darken"
                              : ""
                          }`}
                          code={pokemon?.uniqueCode}
                          onClick={handleClick}
                        >
                          <div
                            className="card-body d-flex justify-content-center align-items-center"
                            code={pokemon?.uniqueCode}
                          >
                            <div className="">
                              <p
                                className="text-center card-title p-0 m-0"
                                name=""
                              >
                                {pokemon?.nameFormFr}
                              </p>
                              <p className="text-center p-0 m-0 code-text">
                                {pokemon?.uniqueCode}
                              </p>
                            </div>
                          </div>
                          <div className=""></div>
                          {pokemon?.a_imgFormName?.length > 0 ? (
                            <>
                              {obscurState ? (
                                <img
                                  src={`https://raw.githubusercontent.com/PokeMiners/pogo_assets/master/Images/Filters/ic_shadow_filter.png`}
                                  className="cardObscurImg"
                                  alt="obscurImg"
                                />
                              ) : (
                                ""
                              )}
                              {pokemon.isACostume ? (
                                <img
                                  src={`https://raw.githubusercontent.com/PokeMiners/pogo_assets/master/Images/Filters/ic_event.png`}
                                  className="cardCostumeImg"
                                  alt="costumeImg"
                                />
                              ) : (
                                ""
                              )}

                              <img
                                src={
                                  `https://raw.githubusercontent.com/PokeMiners/pogo_assets/master/Images/Pokemon%20-%20256x256/Addressable%20Assets/${
                                    shinyState
                                      ? pokemon.a_imgFormShinyName[0]
                                      : pokemon.a_imgFormName[0]
                                  }`

                                }
                                // Si pas trouvé sur le git Pokeminer, cherche sur le git de Nams
                                onError={(e) => {
                                  e.target.src = `https://raw.githubusercontent.com/Namsnugax/Bonus-Assets/main/256x256/${
                                    shinyState
                                      ? pokemon.a_imgFormShinyName[0]
                                      : pokemon.a_imgFormName[0]
                                  }`;
                                  e.target.onerror = null;
                                }}
                                className="card-top"
                                alt={pokemon?.a_imgFormName[0]}
                                code={pokemon?.uniqueCode}
                              />
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </>
          </>
        </div>
      </div>
      <BtnScrollUp />
    </>
  );
};

export default Pokedex;
