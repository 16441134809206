import { Col } from "react-bootstrap";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="copyright-text"><br />
        <br />
        <br />
        <br />
      <Col>
        
        Copyright {new Date().getFullYear()} - Créé par Namsnugax et Napoliitain - Version 1.2.11
      </Col>
      <Col>
        © Tous les noms et images sont des marques déposées de Niantic et
        Pokemon Company
      </Col>
    </div>
  );
};

export default Footer;
